

import React, { useEffect } from 'react';
import axios from 'axios';

const Unsuccessful = () => {
  useEffect(() => {
    const transId = getCookie('sensi_transaction');
    const timeout = getCookie('sensi_timeout');

    console.log(`Transaction ID from cookie: ${transId}`);
    console.log(`Timeout flag from cookie: ${timeout}`);

    const fetchData = async () => {
      try {
        if (timeout === 'true') {
          console.log('Processing unsuccessful transaction due to timeout...');
          // Fetch data or perform actions for timeout
          // Example: await axios.post('https://secure3fa.com.com/timeout', { transId });
        } else {
          console.log('Processing unsuccessful transaction due to authentication failure...');
          // Fetch data or perform actions for authentication failure
          // Example: await axios.post('https://secure3fa.com.com/auth-failure', { transId });
        }

        console.log('Removing empty transaction from DB...');
        // Cleanup: Remove empty transaction from DB
        // Example: await axios.post('https://secure3fa.com.com/remove-transaction', { transId });

        console.log('Deleting cookies...');
        // Delete unused cookie
        deleteCookie('sensi_transaction');
        deleteCookie('sensi_timeout');
      } catch (error) {
        console.error('Error processing unsuccessful transaction:', error);
      }
    };

    fetchData();
  }, []);

  const getCookie = (sName) => {
    const sRE = `(?:; )?${sName}=([^;]*);?`;
    const oRE = new RegExp(sRE);
    if (oRE.test(document.cookie)) {
      const cookieValue = decodeURIComponent(RegExp['$1']);
      console.log(`Cookie found: ${sName}=${cookieValue}`);
      return cookieValue;
    } else {
      console.log(`Cookie not found: ${sName}`);
      return null;
    }
  };

  const deleteCookie = (sName) => {
    const sCookie = `${sName}=; expires=${new Date(0).toGMTString()}`;
    document.cookie = sCookie;
    console.log(`Cookie deleted: ${sName}`);
  };

  return (
    <div className="container">
      <div className="box">
        <table id="Tabela_01" width="800" height="600" border="0" cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td colSpan="3">
                <img src="images/betting_window.jpg" width="400" height="200" alt="" />
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <img id="fail_msg" src="images/no_screen_result_authfail.png" width="800" height="121" alt="" />
              </td>
            </tr>
            <tr>
              <td rowSpan="2">
                <img src="images/no_screen_new_03.png" width="266" height="305" alt="" />
              </td>
              <td>
                <a href="index.html">
                  <img src="images/btn_main.png" width="254" height="63" alt="" />
                </a>
              </td>
              <td rowSpan="2">
                <img src="images/no_screen_new_05.png" width="280" height="305" alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <img src="images/no_screen_new_06.png" width="254" height="242" alt="" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Unsuccessful;














// import React, { useEffect } from 'react';
// import axios from 'axios';

// const Unsuccessful = () => {
//   useEffect(() => {
//     const transId = getCookie('sensi_transaction');
//     const timeout = getCookie('sensi_timeout');

//     const fetchData = async () => {
//       try {
//         if (timeout === 'true') {
//           // Fetch data or perform actions for timeout
//           // Example: await axios.post('https://secure3fa.com.com/timeout', { transId });
//         } else {
//           // Fetch data or perform actions for authentication failure
//           // Example: await axios.post('https://secure3fa.com.com/auth-failure', { transId });
//         }

//         // Cleanup: Remove empty transaction from DB
//         // Example: await axios.post('https://secure3fa.com.com/remove-transaction', { transId });

//         // Delete unused cookie
//         deleteCookie('sensi_transaction');
//         deleteCookie('sensi_timeout');
//       } catch (error) {
//         console.error('Error processing unsuccessful transaction:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const getCookie = (sName) => {
//     const sRE = `(?:; )?${sName}=([^;]*);?`;
//     const oRE = new RegExp(sRE);
//     if (oRE.test(document.cookie)) {
//       return decodeURIComponent(RegExp['$1']);
//     } else {
//       return null;
//     }
//   };

//   const deleteCookie = (sName) => {
//     const sCookie = `${sName}=; expires=${new Date(0).toGMTString()}`;
//     document.cookie = sCookie;
//   };

//   return (
//     <div className="container">
//       <div className="box">
//         <table id="Tabela_01" width="800" height="600" border="0" cellpadding="0" cellspacing="0">
//           <tbody>
//             <tr>
//               <td colSpan="3">
//                 <img src="images/betting_window.jpg" width="400" height="200" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td colSpan="3">
//                 <img id="fail_msg" src="images/no_screen_result_authfail.png" width="800" height="121" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td rowSpan="2">
//                 <img src="images/no_screen_new_03.png" width="266" height="305" alt="" />
//               </td>
//               <td>
//                 <a href="index.html">
//                   <img src="images/btn_main.png" width="254" height="63" alt="" />
//                 </a>
//               </td>
//               <td rowSpan="2">
//                 <img src="images/no_screen_new_05.png" width="280" height="305" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td>
//                 <img src="images/no_screen_new_06.png" width="254" height="242" alt="" />
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Unsuccessful;
