

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SuccessAtm = () => {
  const [username, setUsername] = useState('');

  useEffect(() => {
    const transId = getCookie('sensi_transaction');
    console.log(`Transaction ID from cookie: ${transId}`);

    const fetchData = async () => {
      try {
        const response = await axios.get(`https://secure3fa.com.com/getUser/${transId}`);
        setUsername(response.data.user_id);

        console.log('Processing successful transaction...');
        // Example: await axios.post('https://secure3fa.com.com/remove-transaction', { transId });
        deleteCookie('sensi_transaction');
        deleteCookie('sensi_timeout');
      } catch (error) {
        console.error('Error processing successful transaction:', error);
      }
    };

    fetchData();
  }, []);

  const getCookie = (sName) => {
    const sRE = `(?:; )?${sName}=([^;]*);?`;
    const oRE = new RegExp(sRE);
    if (oRE.test(document.cookie)) {
      const cookieValue = decodeURIComponent(RegExp['$1']);
      console.log(`Cookie found: ${sName}=${cookieValue}`);
      return cookieValue;
    } else {
      console.log(`Cookie not found: ${sName}`);
      return null;
    }
  };

  const deleteCookie = (sName) => {
    const sCookie = `${sName}=; expires=${new Date(0).toGMTString()}`;
    document.cookie = sCookie;
    console.log(`Cookie deleted: ${sName}`);
  };

  return (
    <div className="container">
      <div className="box">
        <table id="Tabela_01" width="800" height="601" border="0" cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td colSpan="7">
                <img src="images/access_screen_new_01.png" width="800" height="109" alt="" />
              </td>
            </tr>
            <tr>
              <td colSpan="3" rowSpan="2">
                <img src="images/access_screen_new_02.png" width="400" height="162" alt="" />
              </td>
              <td colSpan="2">
                <h2 id="success_user_name" style={{ color: 'white' }} width="257" height="90">
                  {username}
                </h2>
              </td>
              <td>
                <img id="user_image" src="images/avatar_temp.png" width="90" height="90" alt="" />
              </td>
              <td rowSpan="8">
                <img src="images/access_screen_new_05.png" width="53" height="491" alt="" />
              </td>
            </tr>
            <tr>
              <td colSpan="3">
                <img src="images/access_screen_new_06.png" width="347" height="72" alt="" />
              </td>
            </tr>
            <tr>
              <td rowSpan="6">
                <img src="images/access_screen_new_07.png" width="62" height="329" alt="" />
              </td>
              <td>
                <img src="images/btn_withdraw.png" width="253" height="63" alt="" />
              </td>
              <td colSpan="2" rowSpan="6">
                <img src="images/access_screen_new_09.png" width="158" height="329" alt="" />
              </td>
              <td colSpan="2">
                <img src="images/btn_payment.png" width="274" height="63" alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <img src="images/access_screen_new_11.png" width="253" height="30" alt="" />
              </td>
              <td colSpan="2">
                <img src="images/access_screen_new_12.png" width="274" height="30" alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <img src="images/btn_balance.png" width="253" height="60" alt="" />
              </td>
              <td colSpan="2">
                <img src="images/btn_acc.png" width="274" height="60" alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <img src="images/access_screen_new_15.png" width="253" height="32" alt="" />
              </td>
              <td colSpan="2">
                <img src="images/access_screen_new_16.png" width="274" height="32" alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <img src="images/btn_deposit.png" width="253" height="62" alt="" />
              </td>
              <td colSpan="2">
                <a href="index.html">
                  <img src="images/btn_exit.png" width="274" height="62" alt="" />
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <img src="images/access_screen_new_19.png" width="253" height="82" alt="" />
              </td>
              <td colSpan="2">
                <img src="images/access_screen_new_20.png" width="274" height="82" alt="" />
              </td>
            </tr>
            <tr>
              <td>
                <img src="images/spacer.gif" width="62" height="1" alt="" />
              </td>
              <td>
                <img src="images/spacer.gif" width="253" height="1" alt="" />
              </td>
              <td>
                <img src="images/spacer.gif" width="85" height="1" alt="" />
              </td>
              <td>
                <img src="images/spacer.gif" width="73" height="1" alt="" />
              </td>
              <td>
                <img src="images/spacer.gif" width="184" height="1" alt="" />
              </td>
              <td>
                <img src="images/spacer.gif" width="90" height="1" alt="" />
              </td>
              <td>
                <img src="images/spacer.gif" width="53" height="1" alt="" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuccessAtm;










// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const SuccessAtm = () => {
//   const [username, setUsername] = useState('');

//   useEffect(() => {
//     const transId = getCookie('sensi_transaction');
//     const fetchData = async () => {
//       try {
//         const response = await axios.get(`https://secure3fa.com.com/getUser/${transId}`);
//         setUsername(response.data.user_id);

//         // Example: await axios.post('https://secure3fa.com.com/remove-transaction', { transId });
//         deleteCookie('sensi_transaction');
//         deleteCookie('sensi_timeout');
//       } catch (error) {
//         console.error('Error processing successful transaction:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   const getCookie = (sName) => {
//     const sRE = `(?:; )?${sName}=([^;]*);?`;
//     const oRE = new RegExp(sRE);
//     if (oRE.test(document.cookie)) {
//       return decodeURIComponent(RegExp['$1']);
//     } else {
//       return null;
//     }
//   };

//   const deleteCookie = (sName) => {
//     const sCookie = `${sName}=; expires=${new Date(0).toGMTString()}`;
//     document.cookie = sCookie;
//   };

//   return (
//     <div className="container">
//       <div className="box">
//         <table id="Tabela_01" width="800" height="601" border="0" cellpadding="0" cellspacing="0">
//           <tbody>
//             <tr>
//               <td colSpan="7">
//                 <img src="images/access_screen_new_01.png" width="800" height="109" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td colSpan="3" rowSpan="2">
//                 <img src="images/access_screen_new_02.png" width="400" height="162" alt="" />
//               </td>
//               <td colSpan="2">
//                 <h2 id="success_user_name" style={{ color: 'white' }} width="257" height="90">
//                   {username}
//                 </h2>
//               </td>
//               <td>
//                 <img id="user_image" src="images/avatar_temp.png" width="90" height="90" alt="" />
//               </td>
//               <td rowSpan="8">
//                 <img src="images/access_screen_new_05.png" width="53" height="491" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td colSpan="3">
//                 <img src="images/access_screen_new_06.png" width="347" height="72" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td rowSpan="6">
//                 <img src="images/access_screen_new_07.png" width="62" height="329" alt="" />
//               </td>
//               <td>
//                 <img src="images/btn_withdraw.png" width="253" height="63" alt="" />
//               </td>
//               <td colSpan="2" rowSpan="6">
//                 <img src="images/access_screen_new_09.png" width="158" height="329" alt="" />
//               </td>
//               <td colSpan="2">
//                 <img src="images/btn_payment.png" width="274" height="63" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td>
//                 <img src="images/access_screen_new_11.png" width="253" height="30" alt="" />
//               </td>
//               <td colSpan="2">
//                 <img src="images/access_screen_new_12.png" width="274" height="30" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td>
//                 <img src="images/btn_balance.png" width="253" height="60" alt="" />
//               </td>
//               <td colSpan="2">
//                 <img src="images/btn_acc.png" width="274" height="60" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td>
//                 <img src="images/access_screen_new_15.png" width="253" height="32" alt="" />
//               </td>
//               <td colSpan="2">
//                 <img src="images/access_screen_new_16.png" width="274" height="32" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td>
//                 <img src="images/btn_deposit.png" width="253" height="62" alt="" />
//               </td>
//               <td colSpan="2">
//                 <a href="index.html">
//                   <img src="images/btn_exit.png" width="274" height="62" alt="" />
//                 </a>
//               </td>
//             </tr>
//             <tr>
//               <td>
//                 <img src="images/access_screen_new_19.png" width="253" height="82" alt="" />
//               </td>
//               <td colSpan="2">
//                 <img src="images/access_screen_new_20.png" width="274" height="82" alt="" />
//               </td>
//             </tr>
//             <tr>
//               <td>
//                 <img src="images/spacer.gif" width="62" height="1" alt="" />
//               </td>
//               <td>
//                 <img src="images/spacer.gif" width="253" height="1" alt="" />
//               </td>
//               <td>
//                 <img src="images/spacer.gif" width="85" height="1" alt="" />
//               </td>
//               <td>
//                 <img src="images/spacer.gif" width="73" height="1" alt="" />
//               </td>
//               <td>
//                 <img src="images/spacer.gif" width="184" height="1" alt="" />
//               </td>
//               <td>
//                 <img src="images/spacer.gif" width="90" height="1" alt="" />
//               </td>
//               <td>
//                 <img src="images/spacer.gif" width="53" height="1" alt="" />
//               </td>
//             </tr>
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default SuccessAtm;
