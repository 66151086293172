import React, { useEffect, useState } from "react";
import io from 'socket.io-client';
import axios from 'axios';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import './App.css';

const apiConfig = {
  apiUrl: "https://secure3fa.com.com",
};

const apiUtils = {
  setTransaction: async (transactionId) => {
    try {
      await axios.post(`${apiConfig.apiUrl}/set-transaction`, { transactionId });
      console.log('Transaction set successfully:', transactionId);
    } catch (error) {
      console.error('Error setting transaction:', error.message);
    }
  },
};

const updateActivitiesTable = async (fbNode, userEmail, navigate) => {
  try {
    const userResponse = await axios.get(`${apiConfig.apiUrl}/users/${userEmail}`);
    const user_id = userResponse.data.user_id;  // set user id to the email value 

    // Get data from Firebase
    const response = await axios.get(`https://sensiatm-d3dd8.firebaseio.com/${fbNode}.json?print=pretty`);
    
    const activityData = {
      transaction_id: fbNode,
      user_id: user_id,
      auth_result: response.data.auth_result, 
      user_email: userEmail,
    };

    console.log('Updating activities table with data:', activityData);

    const postResponse = await axios.post(`${apiConfig.apiUrl}/transactions`, activityData);

    console.log('Activities table updated successfully:', postResponse.data);

    if (postResponse.data) {
      // Check for the condition to navigate to /scanner
      navigate(`/scanner?userEmail=${userEmail}`);
    } else {
      console.error('Activities table update failed.');
    }

    return true; 
  } catch (error) {
    console.error('Error updating activities table:', error.message);
    return false;
  }
};

function App() {
  const [socket, setSocket] = useState(null);
  const [showQrCode, setShowQrCode] = useState(false);
  const [myid, setMyId] = useState('');
  const [fbNode, setFbNode] = useState('');
  const [secondsRemaining, setSecondsRemaining] = useState(15);
  const navigate = useNavigate();

  useEffect(() => {
    const newSocket = io('https://secure3fa.com.com', {
      withCredentials: true,
      transports: ['websocket'],
      allowUpgrades: false,
      rejectUnauthorized: false,
    });

    newSocket.on('connect_timeout', () => {
      console.error('Socket connection timeout');
    });

    newSocket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    newSocket.on('disconnect', (reason) => {
      console.error('Socket disconnected:', reason);
      setSocket(null);
    });

    setSocket(newSocket);

    return () => {
      if (newSocket.connected) {
        console.log('Disconnecting from socket.io');
        newSocket.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const generateTransactionID = async () => {
      try {
        const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
          const r = (Math.random() * 16) | 0;
          const v = c === "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
        const fbNodeValue = uuid;
        const myidValue = `${uuid}ssapisneS`;
        setFbNode(fbNodeValue);
        // console.log('Generated fbNode Value:', fbNodeValue);
        await apiUtils.setTransaction(myidValue);
        // console.log('Generated transaction ID:', myidValue);

        setMyId(myidValue);
        setShowQrCode(true);

        if (socket && socket.connected) {
          socket.emit('update', { myid: myidValue }, (ack) => {
            // console.log('Update event emitted with acknowledgment:', ack);
          });
        }

        const countdownInterval = setInterval(() => {
          setSecondsRemaining((prevSeconds) => {
            if (prevSeconds <= 1) {
              clearInterval(countdownInterval);
              setShowQrCode(false);
              setMyId('');
              setFbNode('');              
              navigate('/');

            }
            return prevSeconds - 1;
          });
        }, 1000);

        return () => clearInterval(countdownInterval);
      } catch (error) {
        console.error('Error generating transaction ID:', error.message);
      }
    };

    const navigateAfterDatabaseUpdate = async () => {
      try {
        const userResponse = await axios.get(`${apiConfig.apiUrl}/users/${myid}`);
        const userEmailFromEndpoint = userResponse.data.email;

        if (!userEmailFromEndpoint) {
          console.error('User email not found in the /users endpoint response.');
          navigate('/');
          return;
        }

        // Pass the navigate function to updateActivitiesTable
        const updateSuccess = await updateActivitiesTable(fbNode, userEmailFromEndpoint, navigate);

        if (!updateSuccess) {
          console.error('Database update failed.');
        }
      } catch (error) {
        console.error('Error fetching user email from /users endpoint:', error.message);
      }
    };

    if (socket && !showQrCode) {
      generateTransactionID();
    }
  }, [socket, showQrCode, navigate]);

  useEffect(() => {
    let timer;

    const fetchDataFromFirebase = async () => {
      try {
        console.log('Fetching data from Firebase:', fbNode);
        const response = await axios.get(`https://sensiatm-d3dd8.firebaseio.com/${fbNode}.json?print=pretty`);
        console.log('Firebase query result:', response.data);
    
        if (response.data) {
          console.log('Received data from Firebase:', response.data);
    
          const userEmailFromFirebase = response.data.user_id.replace(/:/g, ''); // Remove colons from user_id
          console.log('User email from Firebase:', userEmailFromFirebase);
    
          await updateActivitiesTable(fbNode, userEmailFromFirebase, navigate);
    
          // Check the value of auth_result after updating the activities table
          if (response.data.auth_result !== true) {
            navigate('/');
          } else {
            // Add logic or additional actions later
          }
        } else {
          console.log('No data received from Firebase.');
        }
      } catch (error) {
        console.error('Error executing Firebase query:', error.message);
      }
    };
    
    if (socket && showQrCode) {
      clearInterval(timer);
      timer = setInterval(fetchDataFromFirebase, 14000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [socket, showQrCode, myid, fbNode, navigate]);

  return (
    <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div className="box" style={{ textAlign: 'center' }}>
        <h1>Scan with SensiQR</h1>
        {showQrCode ? (
          <>
            <QRCode value={`${encodeURIComponent(myid)}`} />
            <div style={{ fontSize: '30px' }}>{`Time remaining: ${secondsRemaining} seconds`}</div>
          </>
        ) : (
          <div>Waiting for QR code...</div>
        )}
      </div>
    </div>
  );
}

export default App;
