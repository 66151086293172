

import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Success = () => {
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const transactionId = getCookie('sensi_transaction');
    if (transactionId) {
      console.log(`Transaction ID from cookie: ${transactionId}`);
      getUserFromAPI(transactionId);
      deleteCookie('sensi_transaction');
      deleteCookie('sensi_timeout');
    }
  }, []);

  const getUserFromAPI = async (transactionId) => {
    try {
      console.log('Fetching user data from API...');
      const response = await axios.get(`https://secure3fa.com.com/${transactionId}`);
      const username = response.data.user_id;
      setUserName(username);
      console.log(`User data received: ${username}`);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const getCookie = (sName) => {
    const sRE = `(?:; )?${sName}=([^;]*);?`;
    const oRE = new RegExp(sRE);
    if (oRE.test(document.cookie)) {
      const cookieValue = decodeURIComponent(RegExp['$1']);
      console.log(`Cookie found: ${sName}=${cookieValue}`);
      return cookieValue;
    } else {
      console.log(`Cookie not found: ${sName}`);
      return null;
    }
  };

  const deleteCookie = (sName) => {
    const sCookie = `${sName}=; expires=${new Date(0).toGMTString()}`;
    document.cookie = sCookie;
    console.log(`Cookie deleted: ${sName}`);
  };

  return (
    <div className="container">
      <div className="box">
        <table id="Tabela_01" width="800" height="601" border="0" cellpadding="0" cellspacing="0">
          <tbody>
            <tr>
              <td colSpan="7">
                <img src="images/access_screen_01p.png" width="800" height="109" alt="" />
              </td>
            </tr>
            {/* ... (Other table rows) */}
            <tr>
              <td colSpan="2">
                <h2 id="success_user_name" style={{ color: 'white' }} width="257" height="90">
                  {userName}
                </h2>
              </td>
              <td>
                <img id="user_image" src="images/avatar_temp.png" width="90" height="90" alt="" />
              </td>
            </tr>
            {/* ... (Other table rows) */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Success;








// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const Success = () => {
//   const [userName, setUserName] = useState('');

//   useEffect(() => {
//     const transactionId = getCookie('sensi_transaction');
//     if (transactionId) {
//       getUserFromAPI(transactionId);
//       deleteCookie('sensi_transaction');
//       deleteCookie('sensi_timeout');
//     }
//   }, []);

//   const getUserFromAPI = async (transactionId) => {
//     try {
//       const response = await axios.get(`https://secure3fa.com.com/${transactionId}`);
//       const username = response.data.user_id;
//       setUserName(username);
//     } catch (error) {
//       console.error('Error fetching user data:', error);
//     }
//   };

//   const getCookie = (sName) => {
//     const sRE = `(?:; )?${sName}=([^;]*);?`;
//     const oRE = new RegExp(sRE);
//     if (oRE.test(document.cookie)) {
//       return decodeURIComponent(RegExp['$1']);
//     } else {
//       return null;
//     }
//   };

//   const deleteCookie = (sName) => {
//     const sCookie = `${sName}=; expires=${new Date(0).toGMTString()}`;
//     document.cookie = sCookie;
//   };

//   return (
//     <div className="container">
//       <div className="box">
//         <table id="Tabela_01" width="800" height="601" border="0" cellpadding="0" cellspacing="0">
//           <tbody>
//             <tr>
//               <td colSpan="7">
//                 <img src="images/access_screen_01p.png" width="800" height="109" alt="" />
//               </td>
//             </tr>
//             {/* ... (Other table rows) */}
//             <tr>
//               <td colSpan="2">
//                 <h2 id="success_user_name" style={{ color: 'white' }} width="257" height="90">
//                   {userName}
//                 </h2>
//               </td>
//               <td>
//                 <img id="user_image" src="images/avatar_temp.png" width="90" height="90" alt="" />
//               </td>
//             </tr>
//             {/* ... (Other table rows) */}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Success;
