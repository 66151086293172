

// Scanner.js
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Scanner = () => {
  const { userEmail } = useParams();

  useEffect(() => {
    const handleTransactionSuccess = async () => {
      try {
        const redirectUrl = `https://hotponies.com/#/auth?id=${encodeURIComponent(userEmail)}`;
        window.location.href = redirectUrl;
      } catch (error) {
        console.error('Error handling transaction success:', error.message);
        // Handle any errors or fallback logic
      }
    };

    // Trigger the navigation when the component mounts
    handleTransactionSuccess();
  }, [userEmail]);

  return (
    <div className="container">
      <p>Redirecting to Hot Ponies...</p>
    </div>
  );
};

export default Scanner;

