// Login.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css'; 

const Login = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    // For simplicity
    navigate('/App');
  };

  return (
    <div className="login-container">
      <button className="login-button" type="button" onClick={handleLogin}>
        Login Here
      </button>
    </div>
  );
};

export default Login;
