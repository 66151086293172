// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Scanner from './Components/Scanner';
import Success from './Components/Success';
import Unsuccessful from './Components/Unsuccessful';
import SuccessAtm from './Components/SuccessAtm';
import Login from './Components/Login';
// import RegisterEmail from './Components/register/registerEmail';

const root = document.getElementById('root');

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/App" element={<App />} />
        <Route path="/scanner" element={<Scanner />} />
        <Route path="/success" element={<Success />} />
        <Route path="/unsuccessful" element={<Unsuccessful />} />
        <Route path="/successAtm" element={<SuccessAtm />} />
        {/* <Route path="/register-email/*" element={<RegisterEmail />} /> */}

      </Routes>
    </Router>
  </React.StrictMode>
);